export const TIMES_LV = {
   times01 : "Pieņemšanas laiki",
   times02 : "Maksas konsultāciju laiki",
   times03 : "Darba laiki",
   times04 : "Pirmdiena",
   times05 : "Otrdiena",
   times06 : "Trešdiena",
   times07 : "Ceturtdiena",
   times08 : "Piektdiena"
}
