export const LIST_INFORMATION_RU = {
    list_information: [
        {
            item : "Согласно постановлению Кабинета Министров ЛР от 28 августа 2018 г.\nНет. № 555 «Организация и порядок оплаты медицинских услуг».\nОт взноса пациента освобождаются следующие категории жителей Латвии:"
        },
        {
            item : "дети до 18 лет"
        },
        {
            item : "беременные женщины и женщины в послеродовом периоде в срок до 70 дней при получении медицинских услуг, связанных с наблюдением за беременностью и послеродовым наблюдением и течением беременности"
        },
        {
            item : "для лиц, которым установлена инвалидность I или II группы"
        },
        {
            item : "политически репрессированные лица, участники движения национального сопротивления и пострадавшие при ликвидации последствий аварии на Чернобыльской АЭС"
        },
        {
            item : "больные туберкулезом и больные, проходящие обследование на туберкулез"
        },
        {
            item : "психически больные лица, получающие психиатрическое лечение"
        },
        {
            item : "лица, получающие процедуры хронического гемодиализа, гемодиафильтрации и перитонеального диализа на протяжении всего процесса лечения"
        },
        {
            item : "лица, получающие медицинскую помощь при некоторых инфекционных заболеваниях"
        },
        {
            item : "лица, которым неотложная медицинская помощь оказывается бригадами скорой медицинской помощи"
        },
        {
            item : "лица, получающие услуги в учреждениях долгосрочного социального ухода и социальной реабилитации, зарегистрированных в реестре поставщиков социальных услуг"
        },
        {
            item : "лица, проходящие профилактические осмотры и обследования (детская профилактическая медико-санитарная помощь, онкологическая профилактика)"
        },
        {
            item : "лица, которым семейный врач проводит общий осмотр и иммунопрофилактику согласно календарю прививок, а также профилактические осмотры и обследования в рамках государственного онкодиспансеризации"
        },
        {
            item : "лица, которые в рамках календаря прививок проходят вакцинацию, экстренную профилактику судорог, постконтактную иммунизацию против бешенства, а также вакцинацию против гриппа в соответствии с нормативными актами о порядке возмещения расходов на приобретение лекарственных средств и медицинских изделий, предназначенных для амбулаторное лечение"
        },
        {
            item : "лица, находящиеся на длительной искусственной вентиляции легких в домашних условиях"
        },
        {
            item : "доноры органов"
        },
        {
            item : "лица, получающие медицинскую помощь на дому или паллиативную помощь в стационарном медицинском учреждении в объеме, определяемом Кабинетом Министров, а также паллиативную помощь, оказываемую семейным врачом во время визита на дом"
        },
        {
            item : "малообеспеченные лица, признанные таковыми в соответствии с нормативными актами о порядке признания семьи или отдельно проживающего лица малоимущими"
        },
        {
            item : "работники скорой медицинской помощи"
        },
        {
            item : "лица, ищущие убежища"
        }
    ]
}
