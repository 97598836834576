export const DAYS_RU = {
    list_days : [
        {
            id: "1",
            item: "Понедельник"
        },
        {
            id: "2",
            item: "Вторник"
        },
        {
            id: "3",
            item: "Среда"
        },
        {
            id: "4",
            item: "Четверг"
        },
        {
            id: "5",
            item: "Пятница"
        },
    ]
}
