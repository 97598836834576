export const ACCESSIBLE_LV = {
    accessible01 : "Pieejamība",
    accessible02 : "Ir piekļuve cilvēkiem ar īpašām vajadzībām",
    accessible03 : "Ir piekļuve WC cilvēkiem ar īpašām vajadzībām",
    accessible04 : "Ir piekļuve personām ar bērnu ratiņiem, kā arī iespēja  bērnu ratiņus atstāt drošā vietā",
    accessible05 : "Ir bērnu pārtinamā virsma",
    accessible06 : "Ir uzgaidāmā telpa un sēdvietas",
    accessible07 : "Ir lifts",
    accessible08 : "Auto  var parkot Brīvī bas ielā",
    accessible09 : "Pieņemam norēķinu kartes"
}
