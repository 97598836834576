export const COMMON_RU = {
    home : "Начало",
    about : "О нас",
    services : "Услуги",
    prices : "Прайс-лист",
    info : "Информация для пациентов",
    contacts : "Контакты и время работы",
    eapointment : "Е - запись",

    company01 : "SIA \"Jautrītes Liepiņa врачебная практика в области оториноларингологии\"",
    company02 : "SIA \"Jautrītes Liepiņa врачебная практика\n" +
                "в области оториноларингологии\"",

    btn_variant01 : "Е - запись",
    btn_variant02 : "глянь сюда",
    btn_variant03: "(см. раздел Контакты)",
    btn_variant04: "Контакты и время работ",

    list_header_01 : "В клинике проводятся все необходимые обследования и хирургические манипуляции.",
    list_header_02 : "По направлению семейного врача:",
    list_header_03 : "Без направления семейного врача:",

    contracts01 : "Договорные обязательства",
    contracts02 : "Мы принимаем полисы медицинского страхования Balta, BTA, Ergo, Gjensidige, Seesam, включая договорные обязательства перед государством.",

    covid : "В связи с инфекцией Covid мы приглашаем вас следить за изменениями в алгоритме, установленном Центрами профилактики и контроля заболеваний, с которыми можно ознакомиться здесь:"
}
