export const ACCESSIBLE_RU = {
    accessible01 : "Доступность",
    accessible02 : "Есть доступ для людей с особыми потребностями",
    accessible03 : "Есть доступ к туалету для людей с особыми потребностями",
    accessible04 : "Доступ для людей с колясками, а также возможность оставить коляски в безопасном месте",
    accessible05 : "Есть пеленальная поверхность",
    accessible06 : "Есть зал ожидания и места для сидения",
    accessible07 : "есть лифт",
    accessible08 : "Вы можете припарковать свой автомобиль на улице Бриви Бас.",
    accessible09 : "Мы принимаем платежные карты"
}
