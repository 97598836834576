
function LineLang() {

    return (
        <>
            <div style={{
                width: '100%',
                height: '2px',
                marginBottom: 'var(--size--padding40)',
                background: 'var(--color--line--lang)',
            }}></div>
        </>
    )
}

export default LineLang