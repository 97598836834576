export const TIMES_EN = {
   times01 : "Admission times",
   times02 : "Paying consultation times",
   times03 : "Working hours",
   times04 : "Monday",
   times05 : "Tuesday",
   times06 : "Wednesday",
   times07 : "Thursday",
   times08 : "Friday"
}
