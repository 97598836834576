export const SECTIONS_RU = {
    section_header_part01 : "Ковидная безопасность",
    section_header_part02 : "Телефон: на рецепции: +371 67186950, на практике: +371 67223546",
    section_header_part03 : "Электронная почта: info@lors.lv",
    section_header_part04 : "Е - запись",
    section_header_part05 : "SIA \"Jautrītes Liepiņa врачебная практика в области оториноларингологии\"",
    section_header_part06 : "Будем рады помочь в диагностике и лечении заболеваний уха, горла и носа",
    section_header_part07 : "Часы работы - больше информации здесь",

    section_home_header : "Высококвалифицированные и сертифицированные специалисты.\nДиагностика и лечение.\nСамые современные технологии",
    section_home_part01 : "Современная практика врачей в центре Риги, специализирующихся на диагностике и лечении заболеваний уха, горла и носа у детей и взрослых.",
    section_home_part02 : "В практике работают сертифицированные и опытные специалисты-оториноларингологи, которые регулярно совершенствуют свои знания на семинарах и научных конференциях в Латвии и за рубежом.",
    section_home_part03 : "В практике все необходимые исследования проводятся с использованием самых современных методов обследования на медицинском оборудовании высокого разрешения последнего поколения, которые являются малоинвазивными и безболезненными и позволяют максимально быстро и точно диагностировать заболевание, оказывая индивидуально адаптированные и успешное лечение.",

    section_about_part01 : "Врачебная практика в области оториноларингологии основана в 2000 году, ее руководителем и лечащим врачом является Яутрите Лиепиня. В своем более чем 35-летнем стаже работы врач специализируется на диагностике и лечении заболеваний уха, горла и носа как у взрослых, так и у детей.",
    section_about_part02 : "В 2015 году Др. Элина Элиас-Виганте, а в 2017 г. - др. Элина Межзиле, сестра Даце Сидоровой, помогает выполнять ежедневную работу практики.",
    section_about_part03 : "Все практикующие врачи сертифицированы, являются членами Латвийской ассоциации оториноларингологов, регулярно повышают свои знания на местных и международных конференциях и конгрессах.",
    section_about_part04 : "Практика находится в договорных отношениях с Национальной службой здравоохранения.",

    section_services_part00 : "",

    section_prices_part01 : "Для информации:",
    section_prices_part02 : "Посещение без направления или в случае свободного выбора пациента, профилактический осмотр (в том числе для детей), комиссия шофера являются платными услугами.",
    section_prices_part03 : "Категории населения, освобожденные от сооплаты пациента",
    section_prices_part04 : "Порядок организации и оплаты медицинских услуг (положение МК № 555)",

    section_info_part01 : "На практике прием пациентов осуществляется в соответствии со следующей процедурой:",
    section_info_list01_part01 : "по предварительной записи",
    section_info_list01_part02 : "по направлению семейного врача или специалиста (не распространяется на платные услуги)",
    section_info_list01_part03 : "при предъявлении документа, удостоверяющего личность",
    section_info_list01_part04 : "предъявить полис медицинского страхования (если применимо)",
    section_info_list01_part05 : "дети должны приходить в сопровождении родителей или других законных представителей",
    section_info_list01_part01_link : "(см. раздел Контакты)",
    section_info_part02 : "(законный представитель ребенка должен доказать свое правовое положение по отношению к несовершеннолетнему ребенку, родители для этого должны иметь соответствующую запись в паспорте отца или матери или свидетельстве о рождении ребенка с указанием фамилий родителей, иждивенцев лиц, в том числе бабушек и дедушек, братьев, сестер, других родственников необходимо оформить нотариальную доверенность на представление законных прав и интересов ребенка)",
    section_info_part03 : "Категории населения, освобожденные от сооплаты пациента",
    section_info_list02_part01 : "необходимо представить подтверждающий документ",
    section_info_list02_part02 : "необходимо прибыть в защитной маске (можно приобрести в аптеке на 1 этаже)",
    section_info_list02_part03 : "outerwear must be handed over to the cloakroom on the 2nd floor",

        section_info_part04 : "Категории населения, освобожденные от больничного взноса",

        section_info_part05 : "Согласно постановлению Кабинета Министров ЛР от 28 августа 2018 г.",
        section_info_part06 : "Нет. № 555 «Организация и порядок оплаты медицинских услуг».",
        section_info_part07 : "От взноса пациента освобождаются следующие категории жителей Латвии:",

    section_contacts_part01 : "Прибытие пациентов точно определяется по времени и с лицевой маской. Дети могут иметь одного сопровождающего.",
    section_contacts_part02 : "Посещение очных или дистанционных консультаций только по предварительной записи, записаться по телефону +371 67186950 или",
    section_contacts_part03 : "Адрес: Бривибас 68, Рига",
    section_contacts_part04 : "(вход с улицы Гертруды)",

    section_footer_part01 : "SIA \"Jautrītes Liepiņa врачебная практика\nв области оториноларингологии\"",
    section_footer_part02 : "Фактический адрес",
    section_footer_part03 : "Brīvības 68, \n" +
                            "LV-1011, Rīga\n" +
                            "Latvija ",
    section_footer_part04 : "Реквизиты:",
    section_footer_part05 : "SIA \"Jautrītes Liepiņa врачебная практика\nв области оториноларингологии\"\n" +
                            "Код учреждения рег. 0190-77406\n" +
                            "Рег.№ 40103743362\n" +
                            "Номер счета: LV12UNLA0050021628434",
    section_footer_part06 : "Kонтакты",
    section_footer_part07 : "Электронная почта: info@lors.lv",
    section_footer_part08 : "Телефон",
    section_footer_part09 : "В приемной: +371 67186950\n" +
                            "На практике: +371 6722354"
}
