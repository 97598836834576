export const TIMES_RU = {
   times01 : "Время приема",
   times02 : "Время платных консультаций",
   times03 : "Рабочее время",
   times04 : "Понедельник",
   times05 : "Вторник",
   times06 : "Среда",
   times07 : "Четверг",
   times08 : "Пятница"
}
