export const DAYS_LV = {
    list_days : [
        {
            id: "1",
            item: "Pirmdiena"
        },
        {
            id: "2",
            item: "Otrdiena"
        },
        {
            id: "3",
            item: "Trešdiena"
        },
        {
            id: "4",
            item: "Ceturtdiena"
        },
        {
            id: "5",
            item: "Piektdiena"
        },
    ]
}