export const DAYS_EN = {
    list_days : [
        {
            id: "1",
            item: "Monday"
        },
        {
            id: "2",
            item: "Tuesday"
        },
        {
            id: "3",
            item: "Wednesday"
        },
        {
            id: "4",
            item: "Thursday"
        },
        {
            id: "5",
            item: "Friday"
        },
    ]
}
